import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Box, Button, Typography, Grid } from "@mui/material";

import { Link } from "react-router-dom";

const CardItem = ({ to, title, icon, handleLink }) => {
  return (
    <Grid
      sx={{
        display: "flex",
        justifyContent: "center",
      }}
      item
      xs={12}
      md={2}
      lg={"2"}
      px={1}
    >
      <Box
        disableRipple
        onClick={handleLink}
        sx={{
          my: 1,
          pt: 0,
          width: "100%",
          display: "flex",
          flexDirection: "column",
          "& .arrowIcon": { display: "none" },
          "@media only screen and (max-width:600px)": {
            px: 2,
            width: "339px",
            height: "52px",
            my: 1,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            boxShadow: " 0px 0px 4px 0px rgba(0, 0, 0, 0.25)",
            "& .arrowIcon": { display: "block" },
          },
        }}
        component={to ? Link : Button}
        to={to}
      >
        <ArrowBackIosNewIcon
          fontSize="8px"
          color="disabled"
          className="arrowIcon"
        />
        <Box
          sx={{
            "@media only screen and (max-width:600px)": {
              display: "flex",
              columnGap: 1,
              flexDirection: "row-reverse",
              justifyContent: "space-between",
              "& .textBtn": {
                pt: 1,
              },
            },
          }}
        >
          {icon}
          <Typography
            sx={{
              color: "black",
              fontSize: 20,
              fontWeight: 400,
              fontFamily: "IranSansX",
            }}
            className="textBtn"
          >
            {title}
          </Typography>
        </Box>
      </Box>
    </Grid>
  );
};

export default CardItem;
