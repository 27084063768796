import { Button } from "@mui/material";

export const CustomButton = ({
  title = "خرید",
  handleButton = () => {},
  sx,
}) => {
  return (
    <Button
      onClick={handleButton}
      sx={{
        fontFamily: "IranSansX",
        mt: 2,
        color: "#fff",
        width: "100%",
        fontWeight: "bolder",
        borderRadius: 8,
        height: "50.212px",
        backgroundColor: "#E64225",
        "&:hover": {
          backgroundColor: "#E64225",
          boxShadow: "none",
          outline: "none",
        },
        "&:active": {
          backgroundColor: "#E64225",
          boxShadow: "none",
          borderColor: "#000",
        },
        ...sx,
      }}
    >
      {title}
    </Button>
  );
};
