import { Grid, Typography } from "@mui/material";

export const CustomHeader = ({ title = "سیمکارت های محبوب آپتل", sx }) => {
  return (
    <Grid sx={{ textAlign: "center", ...sx }} lg md xs>
      <Typography
        sx={{
          fontFamily: "IranSansX",
          fontWeight: 700,
          textAlign: "center",
          fontSize: 30,
          color: "#E74011",
          my: 3,
          "@media only screen and (max-width:600px)": {
            fontSize: 22,
          },
        }}
      >
        {title}
      </Typography>
    </Grid>
  );
};
