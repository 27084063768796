import { Box, Grid, Typography } from "@mui/material";
import CallAptel from "../asset/img/Call-Aptel.png";
import { CustomHeader } from "./customHeader";
import { convertToPersianNumber } from "../util/persianToEnglishNumber";

export const About = () => {
  const persianNumber = convertToPersianNumber("09991000000");

  return (
    <Grid
      sx={{
        display: { xs: "grid", md: "grid", lg: "flex" },
        mt: { xs: 10, md: 10, lg: 20 },
        justifyContent: "space-evenly",
        columnGap: 2,
        my: 5,
        "@media only screen and (max-width:768px)": {
          "& img": {
            width: "202px",
            height: "178px",
          },
        },
      }}
    >
      <Box sx={{ textAlign: "center" }}>
        <img src={CallAptel} alt="Call Aptel" />
      </Box>
      <Box
        sx={{
          textAlign: { sx: "center", md: "center", lg: "right" },
          width: { xs: "unset", md: "unset", lg: 489 },
          pt: { lg: 6 },
          "& p": {
            direction: "rtl",
            px: 2,
            textAlign: "right",
            lineHeight: "28px",
            fontFamily: "IranSansX",
            "@media only screen and (max-width:600px)": {
              textAlign: "right",
            },
          },
        }}
      >
        <CustomHeader title="با ما تماس بگیرید" />
        <Typography>
          {`کارشناسان مرکز پشتیبانی مشترکین آپتل، ${convertToPersianNumber(
            24
          )} ساعت شبانه روز، ${convertToPersianNumber(
            7
          )} روز هفته و ${convertToPersianNumber(365)}
          روز سال، پاسخگوی پرسش‌ها، درخواست‌ها و مشکلات احتمالی مشترکین گرامی
          می‌باشند.`}
        </Typography>

        <Typography>
          {`برای برقراری ارتباط با این مرکز می‌توانید شماره ${convertToPersianNumber(
            100
          )} را از خطوط آپتل یا
          شماره ${persianNumber} را از سایر خطوط شماره‌گیری فرمایید`}
        </Typography>
      </Box>
    </Grid>
  );
};
