import { Divider, Grid, Typography } from "@mui/material";
import "./App.css";
import banner from "./asset/img/bannerF.jpg";
import CardItem from "./components/simCardPopular/cardItem";
import { ReactComponent as FourG } from "./asset/svg/Frame.svg";
import { ReactComponent as Mobile } from "./asset/svg/Group 81720.svg";
import { ReactComponent as SIMCard } from "./asset/svg/Frame (1).svg";
import { ReactComponent as Factor } from "./asset/svg/fi-rr-receipt 1.svg";
import { SimCartCardItem } from "./components/simCardPopular/simCard";
import { SimCartItem } from "./components/simCartCard/simCartItem";
import { CustomHeader } from "./components/customHeader";
import { VideoPlayer } from "./components/videoPlayer";
import { About } from "./components/about";

import imageFour from "./asset/img/img4.png";
import imageFOne from "./asset/img/img3.png";
import imageTwo from "./asset/img/img2.png";
import imageThree from "./asset/img/img1.png";
import { convertToPersianNumber } from "./util/persianToEnglishNumber";

const oneItemsData = {
  isTurk: false,
  cardInfo: {
    title: "دائمی برنز 3 ستاره",
    oneItem: `${convertToPersianNumber(75)} گیگ اینترنت`,
    twoItem: `${convertToPersianNumber(
      1500
    )} دقیقه مکالمه (درون و برون شبکه)   `,
    threeItem: `${convertToPersianNumber(1500)} عدد پیامک (درون و برون شبکه) `,
    lastItem: "شش ماهه",
  },
};
//
const twoItemsData = {
  isTurk: false,
  cardInfo: {
    title: `${convertToPersianNumber(1)} ستاره اعتباری`,
    oneItem: `${convertToPersianNumber(10)} گیگ اینترنت`,
    twoItem: `${convertToPersianNumber(200)} دقیقه مکالمه (درون و برون شبکه) `,
    threeItem: `${convertToPersianNumber(200)} عدد پیامک (درون و برون شبکه) `,
    lastItem: "دو ماهه",
  },
};

//
const ThreeItemData = {
  isTurk: true,
  cardInfo: {
    title: ` ترکیه ${convertToPersianNumber(8)} گیگ `,
    oneItem: `${convertToPersianNumber(8)} گیگ اینترنت `,
    twoItem: `${convertToPersianNumber(200)}  دقیقه مکالمه با خطوط ترکیه`,
    threeItem: `${convertToPersianNumber(200)} پیامک از ترکیه به ایران`,
    lastItem: `${convertToPersianNumber(50)} هزار تومان اعتبار اولیه`,
  },
};

function App() {
  return (
    <Grid
      className="banner"
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"center"}
      xs={12}
      md={12}
      lg={12}
    >
      <img src={banner} alt="Banner" />
      <Grid
        className="purchase"
        container
        sx={{
          py: 2,
          px: { xs: 0, md: 0, lg: 25 },
          display: "flex",
          alignItems: "center",
          justifyContent: "space-evenly",
          boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)",
          "& hr": {
            m: 2,
            "@media only screen and (max-width:600px)": {
              m: 0,
            },
          },
        }}
      >
        <CardItem
          handleLink={() => {
            window.location.href =
              "https://simcardstore.aptel.ir/html/bundle-simcard.html?googleAd=true";
          }}
          title="خرید سیمکارت"
          icon={<SIMCard />}
        />
        <Divider orientation="vertical" variant="fullWidth" flexItem />

        <CardItem
          handleLink={() => {
            window.location.href =
              "https://simcardstore.aptel.ir/Action/PayBill?googleAd=true";
          }}
          title="صورت حساب دایمی"
          icon={<Factor style={{ marginRight: "-10px" }} />}
        />
        <Divider orientation="vertical" variant="fullWidth" flexItem />

        <CardItem
          handleLink={() => {
            window.location.href =
              "https://dcstore.aptel.ir/packages/?googleAd=true";
          }}
          title="خرید بسته"
          icon={<FourG />}
        />
        <Divider orientation="vertical" variant="fullWidth" flexItem />

        <CardItem
          handleLink={() => {
            window.location.href =
              "https://dcstore.aptel.ir/directcharge/?googleAd=true";
          }}
          title="خریدشارژ"
          icon={<Mobile />}
        />
      </Grid>
      <CustomHeader />

      <Grid
        className="papularSIM"
        sx={{
          direction: "rtl",
          display: {
            xs: "grid",
            md: "grid",
            lg: "flex",
            columnGap: 50,
            my: 5,
            "@media only screen and (max-width:600px)": {
              columnGap: 0,
            },
          },
          justifyContent: "center",
        }}
      >
        <SimCartCardItem
          onClick={() => {
            window.location.href =
              "https://simcardstore.aptel.ir/simstore/html/select-number.html?bundleid=12d82eb8-05ff-4592-bfc2-98b2cc24a08e&gift=1af1947c-bfc4-45a9-8769-b93a65e9a51b&googleAd=true";
          }}
          data={oneItemsData}
          amount={"300,000"}
        />
        <SimCartCardItem
          onClick={() => {
            window.location.href =
              "https://simcardstore.aptel.ir/simstore/html/select-number.html?bundleid=02d82eb8-05ff-4592-bfc2-98b2cc24a08e&gift=1af1947c-bfc4-45a9-8769-b93a65e9a51b&googleAd=true";
          }}
          data={twoItemsData}
          amount={"40,000"}
        />
        <SimCartCardItem
          onClick={() => {
            window.location.href =
              "https://simcardstore.aptel.ir/salesinside/html/select-number.html?bundleid=45459b7d-3155-472a-963f-e3d1901c4b3a&googleAd=true";
          }}
          data={ThreeItemData}
          amount={"500,000"}
        />
      </Grid>

      <Grid my={5} textAlign={"center"}>
        <CustomHeader title=" سیم‌کارتهای آپتل" />
        <Grid display={"flex"} justifyContent={"center"}>
          <Typography
            sx={{
              width: "600px",
              direction: "rtl",
              px: 1,
              fontSize: 14,
              fontWeight: 500,
              color: "#4D4D4D",
              textAlign: "center",
              fontFamily: "IranSansX",
            }}
          >
            با خرید سیمکارت‌های آپتل، ضمن برخورداری از خدمات اپراتوری در داخل
            کشور، امکان استفاده از خدمات رومینگ با ارزان‌ترین قیمت برای شما
            فراهم خواهد بود.
          </Typography>
        </Grid>
      </Grid>
      <Grid
        sx={{
          direction: "rtl",
          display: { xs: "grid", md: "grid", lg: "flex" },
          justifyContent: "center",
        }}
        columnGap={2}
        my={5}
      >
        <SimCartItem
          handlePurchase={() => {
            window.location.href =
              "https://simcardstore.aptel.ir/simstore/html/landing.html?googleAd=true";
          }}
          headerTile="سیمکارت داخلی"
          image={imageThree}
          content="سیمکارتهای عادی آپتل بهترین انتخاب برای سفرهای سیاحتی و بیزینسی کوتاه مدت هستند."
        />

        <SimCartItem
          handlePurchase={() => {
            window.location.href =
              "https://simcardstore.aptel.ir/salesinside/html/landing.html?googleAd=true";
          }}
          headerTile="سیمکارت گردشگری"
          image={imageTwo}
          content="در صورتیکه مقیم ترکیه هستید، می‌توانید نسبت به خرید سیم‌کارت آپتل با امکان پرداخت با کارت‌های بین المللی اقدام نمایید."
        />

        <SimCartItem
          handlePurchase={() => {
            window.location.href =
              "https://simcardstore.aptel.ir/kitstore/html/landing.html?googleAd=true";
          }}
          headerTile="سیمکارت با استفاده از کیت"
          image={imageFOne}
          content="در صورتیکه مقیم ترکیه هستید، می‌توانید نسبت به خرید سیم‌کارت آپتل با امکان پرداخت با کارت‌های بین المللی اقدام نمایید."
        />
        <SimCartItem
          handlePurchase={() => {
            window.location.href =
              "https://simcardstore.aptel.ir/iranians-of-turkey/html/landing.html?googleAd=true";
          }}
          headerTile="سیمکارت ویژه ایرانیان مقیم ترکیه"
          image={imageFour}
          content="در صورتیکه مقیم ترکیه هستید، می‌توانید نسبت به خرید سیم‌کارت آپتل با امکان پرداخت با کارت‌های بین المللی اقدام نمایید."
        />
      </Grid>
      <CustomHeader title="راهنمای فعالســازی سیـــم کارت آپتل" />
      <VideoPlayer />
      <About />
    </Grid>
  );
}

export default App;
