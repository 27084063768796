export function convertToPersianNumber(englishNumber) {
  const persianDigits = ["۰", "۱", "۲", "۳", "۴", "۵", "۶", "۷", "۸", "۹"];

  // Convert to string if not already
  const strNumber = String(englishNumber);

  return strNumber.replace(/[0-9]/g, function (match) {
    return persianDigits[parseInt(match)];
  });
}
