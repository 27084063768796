import { Box, Grid, Typography } from "@mui/material";

import { CustomButton } from "../submitBtn";

export const SimCartItem = ({
  image = "",
  headerTile = "سیمکارت با استفاده از کیت",
  content = "",
  handlePurchase,
}) => {
  return (
    <Grid
      width={261}
      sx={{
        pb: "15px",
        borderRadius: "19px",
        mb: { xs: 8, md: "none", lg: "none" },
        boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)",
      }}
    >
      <Box position={"relative"} textAlign={"center"}>
        <img
          position={"relative"}
          src={image}
          alt="sim cart APTEL"
          style={{
            objectFit: "cover",
            borderTopLeftRadius: "19px",
            borderTopRightRadius: "19px",
          }}
        />
        {headerTile}
      </Box>

      <Box
        sx={{
          px: 2,
          pt: "15px",
          "& p": {
            color: "#4D4D4D",
            width: "100%",
            fontSize: 12,
            textAlign: "right",
            fontFamily: "IranSansX",
          },
        }}
      >
        <Typography>اعتباری</Typography>
        <Typography>{content}</Typography>
      </Box>
      <Grid px={1}>
        <CustomButton
          handleButton={handlePurchase}
          title="اطلاعات بیشتر و فعال‌سازی"
        />
      </Grid>
    </Grid>
  );
};
