import { Box } from "@mui/material";

export const VideoPlayer = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        "& .video": {
          width: "820px",
          height: "457px",
          "@media only screen and (max-width:600px)": {
            "& .video": {
              width: "340px",
            },
          },
        },
      }}
    >
      <span style={{ display: "block" }}></span>
      <iframe
        className="video"
        src="https://www.aparat.com/video/video/embed/videohash/pIchj/vt/frame"
        allowFullScreen={true}
        webkitallowfullscreen="true"
        mozallowfullscreen="true"
      ></iframe>
    </Box>
  );
};
