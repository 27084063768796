import { Box, Divider, Typography } from "@mui/material";

import { ReactComponent as Internet } from "../../asset/svg/sim-card/1.svg";
import { ReactComponent as Call } from "../../asset/svg/sim-card/2.svg";
import { ReactComponent as Msg } from "../../asset/svg/sim-card/3.svg";
import { ReactComponent as Dollar } from "../../asset/svg/sim-card/4.svg";

export const CardInfo = ({ info }) => {
  return (
    <Box
      sx={{
        direction: "rtl",
        pt: 1.5,
        display: "flex",
        flexDirection: "column",
        rowGap: 2,
      }}
    >
      <Box
        sx={{
          color: "#4D4D4D",
          width: "100%",
          direction: "rtl",
          textAlign: "right",
          fontWeight: "500",
        }}
      >
        {info.title}
      </Box>
      <Box display={"flex"} columnGap={0.5}>
        <Internet />
        <CardItem title={info.oneItem} />
      </Box>

      <Box display={"flex"} columnGap={0.5}>
        <Call />
        <CardItem title={info.twoItem} />
      </Box>

      <Box display={"flex"} columnGap={0.5}>
        <Msg />
        <CardItem title={info.threeItem} />
      </Box>
      <Box display={"flex"} columnGap={0.5}>
        <Dollar />

        <CardItem title={info.lastItem} />
      </Box>
      <Divider />
    </Box>
  );
};

export const CardItem = ({ title }) => {
  return (
    <Typography
      sx={{
        fontFamily: "IranSansX",
        fontSize: 14,
        fontWeight: 400,
      }}
    >
      {title}
    </Typography>
  );
};
