import { Box, Grid, Typography } from "@mui/material";
import { convertToPersianNumber } from "../../util/persianToEnglishNumber";
import SIM from "../../asset/img/SIM-400.png";
import { CardInfo } from "./cardInfo";
import { CustomButton } from "../submitBtn";

export const SimCartCardItem = ({ amount = 600, data, onClick }) => {
  return (
    <Grid
      sx={{
        cursor: "pointer",
        width: "min-content",
        backgroundColor: "#FFFFFF",
        p: "14px",
        boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.20)",
        mb: { xs: 8, md: "none", lg: "non" },
        borderRadius: "15px",
        "@media only screen and (max-width:600px)": {
          p: 1,
          boxShadow: "none",
        },
      }}
    >
      <Box position={"relative"} textAlign={"center"}>
        <img
          src={SIM}
          alt="sim cart APTEL"
          sx={{
            boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.20)",
            position: "relative",
            objectFit: "cover",
            width: { xs: 314, md: 347, lg: 347 },
          }}
        />
        <Box
          sx={{
            position: "absolute",
            top: "110px",
            right: "20px",
            fontSize: 22,
            color: "#fff",
            direction: "rtl",
            textAlign: "right",
            fontWeight: "bolder",
          }}
        >
          <p>{`${convertToPersianNumber(amount)} هزار تومان`}</p>
        </Box>
        {data.isTurk ? (
          <Box
            sx={{
              backgroundColor: "#fff",
              position: "absolute",
              top: "25px",
              right: "20px",
              width: "126px",
              height: "21px",
              borderRadius: "10.5px",
            }}
          >
            <Typography
              style={{
                color: "#E74011",
                textAlign: "center",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: "700",
                lineHeight: "normal",
                paddingTop: 3,
                fontFamily: "IranSansX",
              }}
            >
              ویژه هموطنان ترکیه
            </Typography>
          </Box>
        ) : (
          ""
        )}
      </Box>
      <CardInfo info={data.cardInfo} />
      <Box pt={2}>
        {/* <Typography
          style={{
            textAlign: "right",
            fontFamily: "IranSansX",
            fontWeight: 500,
            fontSize: 14,
            direction: "rtl",
          }}
        >
          سیم‌کارت‌های آپتل بهترین انتخاب برای سفرهای گردشگری و کاری کوتاه مدت.
        </Typography> */}
        <CustomButton handleButton={onClick} />
      </Box>
    </Grid>
  );
};
